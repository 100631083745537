import React, { createContext, useState, useContext } from "react";
import DialogComponent from "./DialogComponent";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState({
    show: false,
    title: "",
    message: "",
    onConfirm: () => {},
    cancelText: "",
    okText: "",
    buttonSize: "",
    footer: "",
  });

  const openDialog = ({
    title,
    message,
    onConfirm,
    cancelText,
    okText,
    buttonSize,
    footer,
  }) => {
    setDialogState({
      show: true,
      title,
      message,
      onConfirm,
      cancelText,
      okText,
      buttonSize,
      footer,
    });
  };

  const closeDialog = () => {
    setDialogState({ ...dialogState, show: false });
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <DialogComponent
        show={dialogState.show}
        title={dialogState.title}
        message={dialogState.message}
        onClose={closeDialog}
        onConfirm={dialogState.onConfirm}
        cancelText={dialogState.cancelText}
        okText={dialogState.okText}
        buttonSize={dialogState.buttonSize}
        footer={dialogState.footer}
      />
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
