import React, { useContext, useEffect } from "react";
import Routes from "./routes/routes";

import PsContext from "./context";
import { APP_TYPE } from "./utils";

const AppContainer = (props) => {
  const context = useContext(PsContext);

  useEffect(() => {
    context.loadSettings();
  }, []);

  if (APP_TYPE == "SAAS") {
    return (
      <>
        <Routes />
      </>
    );
  } else if (context.settings && context.settings.length > 0) {
    return (
      <>
        <Routes />
      </>
    );
  } else {
    return (
      <section>
        <div id="loader">
          <div className="spinner"></div>
        </div>
      </section>
    );
  }
};

export default AppContainer;
