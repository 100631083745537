import React from "react";
import { Button, Modal } from "react-bootstrap";

const DialogComponent = ({
  show,
  title,
  message,
  onClose,
  onConfirm,
  cancelText,
  okText,
  buttonSize,
  footer,
}) => {
  const handleOnCloseClick = (event, reason) => {
    // if (reason === "backdropClick") return;
    if (onClose) onClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleOnCloseClick}
      backdrop="static"
      centered
      className="ps-alert-modal-comp"
    >
      <Modal.Header closeButton={footer === false ? true : false}>
        {title}
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      {footer !== false && (
        <Modal.Footer>
          <Button
            onClick={onClose}
            variant={onConfirm ? "outline-primary" : "primary"}
            size={buttonSize || "sm"}
          >
            {cancelText ? (
              cancelText
            ) : onConfirm ? (
              <>
                <i className="ti-close me-2"></i>Cancel
              </>
            ) : (
              "Ok"
            )}
          </Button>
          {onConfirm && (
            <Button
              className="ms-2"
              onClick={() => {
                onConfirm && onConfirm();
                onClose();
              }}
              size={buttonSize || "sm"}
              autoFocus
            >
              <i className="fa-solid fa-check me-2"></i>
              {okText || "Confirm"}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default DialogComponent;
