import { Spin } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link, useHistory } from "react-router-dom";
import PsContext from "../../context";
import { ServiceUrl } from "../../utils/serviceUrl";

const UserNameInput = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const history = useHistory();

  const [username, setUserName] = useState("");
  const [captcha, setCaptcha] = useState([]);

  useEffect(() => {
    loadCaptcha();
  }, []);

  const loadCaptcha = () => {
    setLoader(true);
    axios.get(ServiceUrl.V2_LOGIN.CAPTCHA).then((res) => {
      if (res["data"].status == "1") {
        setCaptcha(res["data"]);
      }
      setLoader(false);
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() == false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoader(true);
    axios
      .post(ServiceUrl.V2_LOGIN.CHECK_USERNAME, new FormData(form))
      .then((res) => {
        if (res["data"].status == "1") {
          setUserName(res["data"].token);
          history.push("/forgot-password?type=otp&token=" + res["data"].token);
        } else {
          toast.error(res["data"].message || "Error");
          loadCaptcha();
        }
        setLoader(false);
      });
  };
 
  return (
    <div>
      <Spin spinning={loader}>
        <div className="my-3">
          Enter your registered mobile number, or username to change your
          account password.
        </div>
        <Form
          action=""
          method="post"
          noValidate
          validated={validated}
          onSubmit={handleFormSubmit}
        >
          <Row>
            <Col md={12}>
              <Form.Control
                type="text"
                name="username"
                placeholder="Mobile number or username"
                className="fw-bold"
                required
              />
              <Form.Control.Feedback type="invalid">
                Enter your Username
              </Form.Control.Feedback>
            </Col>
          </Row>
          <input type="hidden" name="captcha_code" value={captcha.code} />
          <Row className="mt-3">
            <Col md={12}>
              <img src={captcha.img} className="border me-2" />

              <a onClick={(e) => loadCaptcha()}>
                <i className="fa-solid fa-arrows-rotate"></i>
              </a>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col md={12}>
              <Form.Control
                type="text"
                name="captcha"
                placeholder="Enter Captcha"
                className="fw-bold"
                required
              />
              <Form.Control.Feedback type="invalid">
                Enter your Captcha
              </Form.Control.Feedback>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col md={12}>
              <Button type="submit" className="w-100">
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
      <Row className="mt-4">
        <Col md={12} className="text-center">
          <Link to="/">Back to login</Link>
        </Col>
      </Row>
    </div>
  );
};

export default UserNameInput;
