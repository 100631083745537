import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Input } from "antd";

const PsTextSearchBox = (props) => {
  const [value, setValue] = useState(null);

  const handleOnChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (value != null) {
      const timeoutId = setTimeout(() => callPropsFuc(), 400);
      return () => clearTimeout(timeoutId);
    }
  }, [value]);

  const callPropsFuc = () => {
    if (props.onSuccess) props.onSuccess(value);
  };

  const getSize = () => {
    if (props.size) {
      if (props.size == "sm") return "small";
      else if (props.size == "md") return "medium";
      else if (props.size == "lg") return "large";
    }
    return "small";
  };

  return (
    <>
      {/*} <InputGroup size={props.size || "sm"}>
        <InputGroup.Text>
          <i className="fa-solid fa-search"></i>
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder={props.placeholder || "Search.."}
          size={props.size || "sm"}
          onChange={(e) => handleOnChange(e)}
        />
      </InputGroup>*/}
      <Input
        placeholder={props.placeholder || "Search.."}
        size={getSize()}
        prefix={<i className="fa-solid fa-magnifying-glass"></i>}
        onChange={(e) => handleOnChange(e)}
      />
    </>
  );
};

export default PsTextSearchBox;
