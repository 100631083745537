import { jsonToQuery } from "../utils";
import callApi from "../utils/callApi";
import { ServiceUrl } from "../utils/serviceUrl";

export const _POST_STUDENT_GRIEVANCE = async (searchData = false) => {
  let url = ServiceUrl.IFORM.POST_STUDENT_GRIEVANCE;
  return callApi(url, "post", searchData && searchData);
};
export const _POST_CONTACT_FORM = async (searchData = false) => {
  let url = ServiceUrl.IFORM.POST_CONTACT_FORM;
  return callApi(url, "post", searchData && searchData);
};
export const _UPDATE_CONTACT_FORM = async (searchData = false) => {
  let url = ServiceUrl.IFORM.UPDATE_ENQUIRY;
  return callApi(url, "post", searchData && searchData);
};

export const _POST_EVENT_REGISTRATION = async (searchData = false) => {
  let url = ServiceUrl.IFORM.POST_EVENT_REGISTRATION;
  return callApi(url, "post", searchData && searchData);
};

export const _POST_CONVACATION_REGISTRATION = async (searchData = false) => {
  let url = ServiceUrl.IFORM.CONVACATION_REGISTRATION;
  return callApi(url, "post", searchData && searchData);
};
