import React, { useContext } from "react";
import PsModalWindow from "../../../utils/PsModalWindow";
import { Row, Col, Button } from "react-bootstrap";
import {
  baseUrl,
  printDocument,
  PrintHeaderWithLogo,
  upperCase,
} from "../../../utils";
import PsContext from "../../../context";

const PrintConvacation = (props) => {
  const context = useContext(PsContext);
  const field = (fieldName) => props.dataSource[fieldName] || "-";

  const printClick = () => {
    printDocument("print_convacation_report");
  };

  return (
    <div>
      <PsModalWindow {...props}>
        <Row>
          <Col md={11}>
            <PrintHeaderWithLogo />
          </Col>

          <Col md={1}></Col>
          <Col md={12}>
            <p className="text-center mt-2 font-bold fs-10">
              CONVOCATION REGISTRATION
            </p>
            <p className="text-center font-bold fs-10 m-0">FEBRUARY 2025</p>
          </Col>
        </Row>
        <div id="print_convacation_report">
          <table>
            <tr className="d-none">
              <th colSpan={3}>
                <div>
                  <PrintHeaderWithLogo hi />
                </div>
              </th>
            </tr>
          </table>

          <table style={{ marginTop: "10px" }} cellPadding="5px">
            <tr>
              <td width="250">Name</td>
              <td width="10">:</td>
              <td>{upperCase(field("student_name"))}</td>
            </tr>
            <tr>
              <td width="250">Reg. No</td>
              <td width="10">:</td>
              <td>{field("register_no")}</td>
            </tr>
            <tr>
              <td width="250">Gender</td>
              <td width="10">:</td>
              <td>{upperCase(field("gender"))}</td>
            </tr>
            <tr>
              <td width="250">Course</td>
              <td width="10">:</td>
              <td>{upperCase(field("course"))}</td>
            </tr>
            <tr>
              <td width="250">Shift</td>
              <td width="10">:</td>
              <td>{field("shift")}</td>
            </tr>
            <tr>
              <td width="250">Department</td>
              <td width="10">:</td>
              <td>{field("department")}</td>
            </tr>
            <tr>
              <td width="250">Year Of Passing</td>
              <td width="10">:</td>
              <td>{field("year_passing")}</td>
            </tr>
            <tr>
              <td width="250">APAAR ID</td>
              <td width="10">:</td>
              <td>{field("apaar_id")}</td>
            </tr>
            <tr>
              <td width="250">Residential Address</td>
              <td width="10">:</td>
              <td>{field("address")}</td>
            </tr>
            <tr>
              <td width="250">Mobile No (WhatsApp)</td>
              <td width="10">:</td>
              <td>{field("mobile")}</td>
            </tr>
            <tr>
              <td width="250">Email</td>
              <td width="10">:</td>
              <td>{field("email")}</td>
            </tr>
            <tr>
              <td width="250">Are you pursuing Higher Studies</td>
              <td width="10">:</td>
              <td>{field("is_higher_studies") == "1" ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td width="250">Course Name</td>
              <td width="10">:</td>
              <td>{field("course_name")}</td>
            </tr>
            <tr>
              <td width="250">Major</td>
              <td width="10">:</td>
              <td>{field("major")}</td>
            </tr>
            <tr>
              <td width="250">College/University/Institution</td>
              <td width="10">:</td>
              <td>{field("college_name")}</td>
            </tr>
            <tr>
              <td width="250">Address of College/University/Institution</td>
              <td width="10">:</td>
              <td>{field("college_address")}</td>
            </tr>
            <tr>
              <td width="250">Are you Employed?</td>
              <td width="10">:</td>
              <td>{field("is_employee") == "1" ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td width="250">Designation</td>
              <td width="10">:</td>
              <td>{field("emp_designation")}</td>
            </tr>
            <tr>
              <td width="250">Company/Institution Address</td>
              <td width="10">:</td>
              <td>{field("emp_company_address")}</td>
            </tr>
            <tr>
              <td width="250">Annual Salary</td>
              <td width="10">:</td>
              <td>{field("emp_annual_salary")}</td>
            </tr>
            <tr>
              <td width="250">Are you self-Employed?</td>
              <td width="10">:</td>
              <td>{field("is_self_employee") == "1" ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td width="250">Work</td>
              <td width="10">:</td>
              <td>{field("self_emp_work")}</td>
            </tr>
            <tr>
              <td width="250">Annual Salary</td>
              <td width="10">:</td>
              <td>{field("self_emp_salary")}</td>
            </tr>
            <tr>
              <td width="250">Are you an Entrepreneur?</td>
              <td width="10">:</td>
              <td>{field("is_entrepreneur") == "1" ? "Yes" : "No"}</td>
            </tr>
            <tr>
              <td width="250">Work</td>
              <td width="10">:</td>
              <td>{field("entrepreneur_work")}</td>
            </tr>
            <tr>
              <td width="250">Annual Salary</td>
              <td width="10">:</td>
              <td>{field("entrepreneur_salary")}</td>
            </tr>
          </table>
        </div>
        <Row className="mt-3">
          <Col md={12} className="text-end">
            <Button onClick={() => printClick()}>Print</Button>
          </Col>
        </Row>
      </PsModalWindow>
    </div>
  );
};

export default PrintConvacation;
